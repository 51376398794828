import React from 'react';

const Title1 = () => {
    return (
    <h1 className="u-center-text heading-primary">
        <span className="heading-primary--main">HELLO</span>
        <span className="heading-primary--sub">Welcome to my website</span>
    </h1>
    )
}

export default Title1;