import React from 'react';
// import Title1 from '../components/Title1'

const Header = () => {
    return (
        <header className="header">

            <div className="header__text-box">
                {/* <h1 class="heading-primary">
                    <span class="heading-primary--main">HELLO</span>
                    <span class="heading-primary--sub">Welcome to my website</span>
                </h1> */}
                {/* <Title1/> */}

                {/* <a href="#section-about" class="btn btn--white btn--animated" >About Me</a> */}
            </div>
        </header>
);
}

export default Header;