import React from "react";
import BackgroundG from "../../pages/portfolio/BackgroundG";
import TypingSpeed from "../../pages/portfolio/TypingSpeed";
import Clock from "../../pages/portfolio/Clock";

const MiniPopUp = ({ popupOpen, closePopup }) => {
  const popups = [
    { id: "popup_10", component: <BackgroundG /> },
    { id: "popup_11", component: <TypingSpeed /> },
    { id: "popup_12", component: <Clock /> },
  ];

  return (
    <div>
      {popups.map((popup) => (
        <div
          className="popup"
          id={popup.id}
          key={popup.id}
          style={{ display: popupOpen === popup.id ? "block" : "none" }}
          onClick={closePopup}
        >
          <div className="popup__content" onClick={(e) => e.stopPropagation()}>
            <span className="popup__close" onClick={closePopup}>
              &times;
            </span>
            {popup.component}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MiniPopUp;
