import React, { Fragment } from "react";

import About from "../../sections/portfolio/About";
import Contact from "../../sections/portfolio/Contact";
import Title2 from "../../components/Title2";

import "../../Assets/sass/main.scss";
import Title1 from "../../components/Title1";
import Footer from "../../sections/portfolio/Footer";
import Header from "../../sections/portfolio/Header";
import NavBar from "../../sections/portfolio/NavBar";
import TsParticles from "../../components/TsParticles";

class LandingPage extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("portfolio");
  }
  componentWillUnmount() {
    document.body.classList.toggle("portfolio");
  }
  render() {
    return (
      <Fragment>
        <TsParticles />
        <NavBar />
        <Header />
        <Title1 />
        <Title2 content={"About Me"} />
        <About />
        <Contact />
        <Footer />
      </Fragment>
    );
  }
}

export default LandingPage;
