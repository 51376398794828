import React from "react";
import ProgressRing from "../../components/ProgressRing";

const SoftSkills = () => {
  const skills = [
    { name: "PhotoShop", progress: 40 },
    { name: "Adobe XD", progress: 60 },
    { name: "VS Code", progress: 90 },
    { name: "After effects", progress: 65 },
  ];

  return (
    <div className="skills-soft">
      <h3>
        <i className="fas fa-bezier-curve"></i>Software Skills
      </h3>
      <div className="ul">
        {skills.map((skill, index) => (
          <figure key={index}>
            <ProgressRing radius={80} stroke={5} progress={skill.progress} />
            <figcaption>
              <span>{skill.name}</span>
              <small></small>
            </figcaption>
          </figure>
        ))}
      </div>
    </div>
  );
};

export default SoftSkills;
