import React, { Component } from 'react'
import '../../Assets/sass/pages/backgroundG.scss'

export default class BackgroundG extends Component {
 setGradient =()=> {
    var css = document.querySelector("#css");
    var color1 = document.querySelector(".color1");
    var color2 = document.querySelector(".color2");
    var body = document.getElementById("gradient");
	body.style.background = 
	"linear-gradient(to right, " 
	+ color1.value 
	+ ", " 
	+ color2.value 
	+ ")";
// ! to show the value of the linear-gradient 
	css.textContent = body.style.background + ";";
}

    render() {
        return (
            <div id="gradient">
                <h1>Background Generator</h1>
                <input className="color1" type="color" name="color1" defaultValue="#004080" onInput={this.setGradient}/>>
                <input className="color2" type="color" name="color2" defaultValue="#008080" onInput={this.setGradient}/>
                <h2>Current CSS Background</h2>
                <h3 id="css">""</h3>
            </div>
        )
    }
}
