import React from 'react';
import { Component } from 'react';
import '../../Assets/sass/pages/typingSpeed.scss'


class TypingSpeed extends Component {
    constructor(){
        super();
        this.state = {
            timerRunning: false,
            timer: [0,0,0,0],
            interval: null
        }
    }

// Add leading zero to numbers 9 or below (purely for aesthetics):
 leadingZero = (time)=> {
    if (time <= 9) {
        time = "0" + time;
    }
    return time;
}

// Run a standard minute/second/hundredths timer:
 runTimer= ()=> {
    const theTimer = document.querySelector(".timer");
    let timer = this.state.timer;
    let currentTime = this.leadingZero(timer[0]) + ":" + this.leadingZero(timer[1]) + ":" + this.leadingZero(timer[2]);
    theTimer.innerHTML = currentTime;
    timer[3]++;

    timer[0] = Math.floor((timer[3]/100)/60);
    timer[1] = Math.floor((timer[3]/100) - (timer[0] * 60));
    timer[2] = Math.floor(timer[3] - (timer[1] * 100) - (timer[0] * 6000));
}

// Match the text entered with the provided text on the page:
 spellCheck =()=> {
    const testArea = document.querySelector("#test-area");
    const originText = document.querySelector("#origin-text p").innerHTML;
    const testWrapper = document.querySelector(".test-wrapper");
    let textEntered = testArea.value;
    let originTextMatch = originText.substring(0,textEntered.length);

    if (textEntered === originText) {
        clearInterval(this.state.interval);
        testWrapper.style.borderColor = "#429890";
    } else if (textEntered === originTextMatch) {
            testWrapper.style.borderColor = "#65CCf3";
    } else {
            testWrapper.style.borderColor = "#E95D0F";
    }

}

// Start the timer:
 start = ()=> {
    const testArea = document.querySelector("#test-area");
    let textEnterdLength = testArea.value.length;
    if (textEnterdLength === 0 && !this.state.timerRunning) {
        this.setState({timerRunning: true});
        let interval = setInterval(this.runTimer, 10);
        this.setState({ interval: interval });
    }
    console.log(textEnterdLength);
}

// Reset everything:
 reset = ()=> {
    const testArea = document.querySelector("#test-area");
    const theTimer = document.querySelector(".timer");
    const testWrapper = document.querySelector(".test-wrapper");
    clearInterval(this.state.interval);
    this.setState({interval: null});
    this.setState({timer: [0,0,0,0]});
    this.setState({timerRunning: false});

    testArea.value = "";
    theTimer.innerHTML = "00:00:00";
    testWrapper.style.borderColor = "grey";
}

// Event listeners for keyboard input and the reset

    render(){

    return(
    <div className="typingSpeed">    
        <header className="masthead">
        <h1>Test Your Typing Speed</h1>
    </header>
    <main className="main">
        <article className="intro">
            <p>This is a typing test. Your goal is to duplicate the provided text, EXACTLY, in the field below. The timer starts when you start typing, and only stops when you match this text exactly. Good Luck!</p>
        </article>
        <section className="test-area" >
            <div id="origin-text">
                <p>This is a typing test. Your goal is to duplicate the provided text, EXACTLY, in the field below. The timer starts when you start typing, and only stops when you match this text exactly. Good Luck!</p>
            </div>

            <div className="test-wrapper">
                <textarea id="test-area" 
                onKeyPress={this.start} 
                onKeyUp={this.spellCheck} 
                name="textarea" rows="6" placeholder="The clock starts when you start typing.">
                </textarea>
            </div>

            <div className="meta">
                <section id="clock">
                    <div className="timer">00:00:00</div>
                </section>

                <button id="reset" 
                onClick={this.reset}
                >Start over</button>
            </div>
        </section>
    </main>
    </div>
    )
}
}
export default TypingSpeed;