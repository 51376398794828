import React from "react";
import { FaArrowUp } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { SiCodepen } from "react-icons/si";
import { FaGithubSquare } from "react-icons/fa";

import "./../../Assets/sass/layout/_footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <a href="#top" className="back-to-top">
          <FaArrowUp />
        </a>
        <div className="social-links">
          <a
            href="https://twitter.com/HabbaziJalal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSquareXTwitter />
          </a>
          <a
            href="https://www.linkedin.com/in/jalaleddine/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://codepen.io/jalal-eddine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiCodepen />
          </a>
          <a
            href="https://github.com/Jalal-Eddine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithubSquare />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-1-of-2">
          <div className="footer__navigation">
            <ul className="footer__list">
              <li className="footer__item">
                <a href="#section-about" className="footer__link">
                  About Me
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="mailto:jalaleddine.elhabbazi@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__link"
                >
                  Contact Me
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-1-of-2">
          <p className="footer__copyright">
            &copy;2020 -
            <a href="#about" className="footer__link">
              Jalal-Eddine El-Habbazi
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
