import React, { useState, useEffect } from "react";
import "./../../Assets/sass/layout/_navigation.scss";

const NavBar = () => {
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [color, setColor] = useState("");
  const [classToggle, setClassToggle] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const isScrolled =
      document.documentElement.scrollTop > 99 || document.body.scrollTop > 99;
    setColor(isScrolled ? "sticky" : "");
  };

  const toggleMobileMenu = () => {
    if (collapseOpen) {
      setClassToggle("open-nav");
    } else {
      setClassToggle("");
    }
    setCollapseOpen(!collapseOpen);
  };

  return (
    <div className={`main_h ${color} ${classToggle}`}>
      <div className="row">
        <a className="logo" href="./">
          JE
        </a>
        <div className="mobile-toggle" onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav>
          <ul>
            <NavItem href="./">About Me</NavItem>
            <NavItem href="./projects">Projects</NavItem>
            <NavItem href="./miniprojects">Mini Projects</NavItem>
            <NavItem href="./resume">Resume</NavItem>
            {/* <NavItem href="https://choisylearn.com" external={true}>
              Blog
            </NavItem>*/}
            <NavItem
              href="mailto:jalaleddine.elhabbazi@gmail.com"
              external={true}
            >
              Contact
            </NavItem>
          </ul>
        </nav>
      </div>
    </div>
  );
};

const NavItem = ({ href, external, children }) => {
  const linkProps = {
    href: href,
    target: external ? "_blank" : undefined,
    rel: external ? "noopener noreferrer" : undefined,
  };

  return (
    <li>
      <a {...linkProps}>{children}</a>
    </li>
  );
};

export default NavBar;
