// Projects1.js

import React, { useState } from "react";
import PopUp1 from "./PopUp1";
import ProjectCard from "../../components/ProjectCard";

// Helper function to split an array into chunks
const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

const Projects1 = ({ projects }) => {
  const [popupContent, setPopupContent] = useState(null);

  const openPopup = (popupData) => {
    setPopupContent(popupData);
  };

  const closePopup = (event) => {
    if (
      event.target.classList.contains("popup") ||
      event.target.classList.contains("popup__close")
    ) {
      setPopupContent(null); // Close the popup
    }
  };

  // Split the projects into chunks of 3
  const projectChunks = chunkArray(projects, 3);

  return (
    <div>
      <section className="section-project" id="section-project">
        {projectChunks.map((chunk, index) => (
          <div className="row" key={index}>
            {chunk.map((project) => (
              <ProjectCard
                key={project._id}
                imageUrl={project.imageUrl}
                heading={project.title}
                details={project.details}
                onButtonClick={() => openPopup(project.popup)}
                cardFrontGradient={project.cardFrontGradient}
                cardBackGradient={project.cardBackGradient}
              />
            ))}
          </div>
        ))}
      </section>
      {popupContent && (
        <PopUp1 content={popupContent} closePopup={closePopup} />
      )}
    </div>
  );
};

export default Projects1;
