import React, {Component} from 'react'

export default class ProgressRing extends Component {
    constructor(props) {
      super(props);
  
      const { radius, stroke } = this.props;
  
      this.normalizedRadius = radius - stroke * 2;
      this.circumference = this.normalizedRadius * 2 * Math.PI;
    }

  render() {
    const { radius, stroke, progress } = this.props;
    const strokeDashoffset = this.circumference - progress / 100 * this.circumference;
  
    return (
      <svg>
        <circle
        // className="cbar"
          r={ this.normalizedRadius }
          cx={ radius }
          cy={ radius }
          />
        <circle
        className="cbar"
          stroke="white"
          fill="transparent"
          strokeWidth={ stroke }
          strokeDasharray={ this.circumference + ' ' + this.circumference }
          style={ { strokeDashoffset } }
          r={ this.normalizedRadius }
          cx={ radius }
          cy={ radius }
          />
      </svg>
    );
  }
}