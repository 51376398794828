import React from 'react';

const HrTitle = (props) => {
    return (
        <div style={{color:props.color}} className='u-margin-bottom-small hr-title'>
                <h2 className="heading-tretry">{props.content}</h2>
        </div>
    )
}

export default HrTitle;