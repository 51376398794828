// Project.js

import React, { useEffect, useState, Fragment } from "react";
import { client } from "../../sanityClient"; // Import your Sanity client instance

// Components
import Title2 from "../../components/Title2";
import TsParticles from "../../components/TsParticles";
import HrTitle from "../../components/HrTitle";
import Tabs from "../../components/Tabs";
import Tab from "../../components/Tab";
import Projects1 from "../../sections/Projects/Projects1"; // Import Projects1 component

// Sections
import NavBar from "../../sections/portfolio/NavBar";
import Header from "../../sections/portfolio/Header";
import Footer from "../../sections/portfolio/Footer";

// Styles
import "../../Assets/sass/main.scss";
import Loading from "../../components/Loading";

const Project = () => {
  const [tabsData, setTabsData] = useState([]);

  useEffect(() => {
    document.body.classList.add("portfolio");
    return () => {
      document.body.classList.remove("portfolio");
    };
  }, []);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "tabData"]{
          label,
          title,
          color,
          projects[]->{
            _id,
            title,
            imageUrl,
            details,
            cardFrontGradient,
            cardBackGradient,
            popupId,
            popup-> {
              _id,
              imageUrl,
              heading,
              subHeading,
              text,
              link,
              linkText
            }
          }
        }`
      )
      .then((data) => setTabsData(data))
      .catch(console.error);
  }, []);
  console.log(tabsData);

  return (
    <Fragment>
      <TsParticles />
      <NavBar />
      <Header />
      <Title2 content="My projects" />
      {tabsData.length > 0 ? (
        <Tabs>
          {tabsData.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              isActive={tab.label === localStorage.getItem("activeTab")}
            >
              <Fragment>
                <HrTitle content={tab.title} color={tab.color} />
                {/* Pass projects data to Projects1 component */}
                {tab.projects && (
                  <Projects1
                    key={tab._id} // Ensure key is unique for each tab
                    projects={tab.projects} // Pass projects array directly
                  />
                )}
              </Fragment>
            </Tab>
          ))}
        </Tabs>
      ) : (
        <Loading />
      )}
      <Footer />
    </Fragment>
  );
};

export default Project;
