import React from "react";
import PropTypes from "prop-types";
import { urlFor } from "../sanityClient";

const ProjectCard = ({
  imageUrl,
  heading,
  details,
  onButtonClick,
  cardFrontGradient,
  cardBackGradient,
}) => (
  <div className="col-1-of-3">
    <div className="card">
      <div className="card__side card__side--front">
        <div
          className="card__picture"
          style={{
            backgroundImage: `${cardFrontGradient},url(${urlFor(imageUrl)})`,
          }}
        >
          &nbsp;
        </div>
        <h4 className="card__heading">
          <span
            className="card__heading-span"
            style={{
              backgroundImage: `${cardFrontGradient}`,
            }}
          >
            {heading}
          </span>
        </h4>
        <div className="card__details">
          <ul>
            {details.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className="card__side card__side--back"
        style={{
          backgroundImage: `${cardBackGradient}`,
        }}
      >
        <div className="card__cta">
          <button className="btn btn--white" onClick={onButtonClick}>
            Read More
          </button>
        </div>
      </div>
    </div>
  </div>
);

ProjectCard.propTypes = {
  heading: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default ProjectCard;
