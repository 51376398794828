import React from "react";

const Interests = () => {
  const interests = [
    { icon: "fas fa-chess", name: "Chess" },
    { icon: "fas fa-book", name: "Books" },
    { icon: "fas fa-pen-nib", name: "UX/UI design" },
    { icon: "fas fa-video", name: "Content Creation" },
    { icon: "fas fa-bicycle", name: "Cycling" },
  ];

  return (
    <div className="interests">
      <h3>
        <i className="fas fa-star"></i>Interests
      </h3>
      <div className="interests-items">
        {interests.map((interest, index) => (
          <div className={interest.name.toLowerCase()} key={index}>
            <i className={interest.icon}></i>
            <span>{interest.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Interests;
