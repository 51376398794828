import React from 'react';

const Title2 = (props) => {
    return (
        <div className="u-center-text u-margin-bottom-small">
                <h2 className="heading-secondary">{props.content}</h2>
        </div>
    )
}

export default Title2;