// Tabs.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(() => {
    const storedTab = localStorage.getItem("activeTab");
    return storedTab || children[0].props.label; // Default to first tab if no stored value
  });

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const handleClick = (label) => {
    setActiveTab(label);
  };

  return (
    <div>
      <div className="tabs u-center-text">
        {children.map((child) => {
          const { label } = child.props;
          return (
            <button
              key={label}
              className={label === activeTab ? "tab tab--active" : "tab"}
              onClick={() => handleClick(label)}
            >
              {label}
            </button>
          );
        })}
      </div>
      <div className="tab-content">
        {children.map((child) => {
          if (child.props.label !== activeTab) return null; // Return null instead of undefined
          return child.props.children;
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
};

export default Tabs;
