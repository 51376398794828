import React from "react";

const Languages = () => {
  const languages = [
    { name: "Arabic", percent: 98 },
    { name: "Anglais", percent: 72 },
    { name: "Français", percent: 76 },
    { name: "Chinoise", percent: 15 },
    { name: "Japonaise", percent: 10 },
  ];

  return (
    <div className="skills-prog">
      <h3>
        <i className="fas fa-code"></i>Languages
      </h3>
      <ul>
        {languages.map((language, index) => (
          <li key={index} data-percent={language.percent}>
            <span>{language.name}</span>
            <div className="skills-bar">
              <div
                className="bar"
                style={{ width: `${language.percent}%` }}
              ></div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Languages;
