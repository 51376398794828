import React, { useEffect, Fragment } from "react";
import Experience from "../../sections/resume/Experience";
import Education from "../../sections/resume/Education";
import ProgSkills from "../../sections/resume/ProgSkills";
import SoftSkills from "../../sections/resume/SoftSkills";
import Languages from "../../sections/resume/Languages";
import Interests from "../../sections/resume/Interests";
import "../../Assets/sass/resume.scss";
import TsParticles from "../../components/TsParticles";
import NavBar from "../../sections/portfolio/NavBar";
import Header from "../../sections/portfolio/Header";
import Footer from "../../sections/portfolio/Footer";
import Title2 from "../../components/Title2";

const Resume = () => {
  useEffect(() => {
    document.body.classList.add("portfolio");
    return () => {
      document.body.classList.remove("portfolio");
    };
  }, []);

  return (
    <Fragment>
      <TsParticles />
      <NavBar />
      <Header />
      <Title2 content={"Resume"} />
      <div className="resume">
        {/* <SideBar/> */}
        <div className="func">
          <div className="Exp-Edu">
            <Experience />
            <Education />
          </div>
          <ProgSkills />
          <Languages />
          <SoftSkills />
          <Interests />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Resume;
