import React from "react";

const educationData = [
  {
    title: "OpenClassRooms : Développeur d'application - PHP / Symfony.",
    link: "https://openclassrooms.com/en/paths/59-developpeur-dapplication-php-symfony",
    details: [
      "Link ",
      "Bac+3/4, Diplôme RNCP niveau 6",
      "A mentor to guide me throughout my studies.",
      "HTML5, CSS3, Bootstrap",
      "WordPress, UML",
      "PHP, OOP, MySQL, MVC",
      "Symfony, API REST",
      "Testing, Performance, Project management",
    ],
    duration: "2020 - 2022",
  },
  {
    title: "Complete React Developer",
    link: "https://www.udemy.com/course/complete-react-developer-zero-to-mastery/",
    details: [
      "Complete React Developer in 2020 (w/ Redux, Hooks, GraphQL)",
      "React Basics, React Router, Redux, Firebase, Stripe Payments API, CSS in JS (styled components)",
      "Redux-Saga, Hooks, Context API, GraphQL",
      "Performance, PWA (progressive web app), Testing",
    ],
    duration: "Apr 2020 - Apr 2020",
  },
  {
    title: "Advanced CSS and Sass: Flexbox, Grid, Animations and More!",
    link: "https://www.udemy.com/certificate/UC-04JLKYIO/",
    details: ["Certificate of Completion"],
    duration: "Nov. 2019 - Dec. 2019",
  },
  {
    title: "The Complete Web Developer in 2020: Zero to Mastery.",
    link: "https://www.udemy.com/certificate/UC-addcb895-7c9e-41c7-b32f-7808d2274b4e/",
    details: [
      "Certificate of Completion",
      "HTML, CSS, Bootstrap, React, Redux, HTTP, AJAX, JSON, Node.js & Express.js, PostgreSQL & more - the best course I took so far",
      "Great community of Developers, with a lot of open source projects as playground to practice and work in team",
    ],
    duration: "Sep 2018 - Feb. 2020",
  },
  {
    title: "ESIGELEC: Ecole d’ingénieurs généralistes",
    details: [
      "Parcours: électronique, télécom. Systèmes embarqués et Instrumentation. Dominante: objets connectés.",
      "Worked on Java project with UML & Oracle DB",
      "Language C: in programming microcontrollers",
    ],
    location: "Rouen, France",
    duration: "2016 - 2018",
  },
  {
    title: "Classes préparatoires aux grandes écoles filière MP",
    details: ["CPGE SOPHOS, Tanger, Maroc."],
    duration: "2014 - 2016",
  },
];

const Education = () => {
  return (
    <div className="edu">
      <h3>
        <i className="fa fa-graduation-cap"></i>Education
      </h3>
      <ul>
        {educationData.map((edu, index) => (
          <li key={index}>
            <span>{edu.title}</span>
            {edu.link && (
              <small>
                <a href={edu.link} target="_blank" rel="noopener noreferrer">
                  🔗 {edu.details[0]}
                </a>
              </small>
            )}
            {edu.details.slice(1).map((detail, i) => (
              <small key={i}>&rarr; {detail}</small>
            ))}
            {edu.location && <small>{edu.location}</small>}
            <small>{edu.duration}</small>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Education;
