// Tab.js
import React from "react";
import PropTypes from "prop-types";

const Tab = ({ label, isActive, children }) => {
  return (
    <div className={isActive ? "tab-content--active" : "tab-content"}>
      {children}
    </div>
  );
};

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tab;
