import React, { useEffect, Fragment } from "react";
import MiniProjects from "../../sections/MiniProjects/MiniProjects";
import "../../Assets/sass/main.scss";
import TsParticles from "../../components/TsParticles";
import NavBar from "../../sections/portfolio/NavBar";
import Header from "../../sections/portfolio/Header";
import Footer from "../../sections/portfolio/Footer";
// import MiniProjects2 from '../../sections/MiniProjects/MiniProjects2';

const MiniProject = () => {
  useEffect(() => {
    document.body.classList.add("portfolio");
    return () => {
      document.body.classList.remove("portfolio");
    };
  }, []);

  return (
    <Fragment>
      <TsParticles />
      <NavBar />
      <Header />
      <MiniProjects />
      {/* <MiniProjects2/> */}
      <Footer />
    </Fragment>
  );
};

export default MiniProject;
