import React, { useState } from "react";
import Title2 from "../../components/Title2";
import { DiJavascript1 } from "react-icons/di";
import MiniPopUp from "./MiniPopUp";
import HrTitle from "../../components/HrTitle";

const MiniProjects = () => {
  const [popupOpen, setPopupOpen] = useState("");

  const closePopup = () => {
    setPopupOpen("");
  };

  const openPopup = (popupId) => {
    setPopupOpen(popupId);
  };

  return (
    <div>
      <section className="section-project" id="mini-project">
        <Title2 content="Mini Projects" />
        <HrTitle content="▩ JavaScript" color="#f8e01e" />
        <div className="mini-projects">
          {[
            {
              title: "Background Generator",
              description:
                "It takes two input colors and inserts them into the linear-gradient from left to right. It then shows the linear-gradient color values.",
              popupId: "popup_10",
            },
            {
              title: "Typing Speed",
              description: "To test your typing speed.",
              popupId: "popup_11",
            },
            {
              title: "Clock",
              description: "Digital analog clock with JavaScript.",
              popupId: "popup_12",
            },
          ].map((project, index) => (
            <div className="card" key={index}>
              <div className="face face1 javascript">
                <div className="content">
                  <DiJavascript1 className="mini-projects__icons" />
                  <h3>{project.title}</h3>
                </div>
              </div>
              <div className="face face2">
                <div className="content">
                  <p>{project.description}</p>
                  <button onClick={() => openPopup(project.popupId)}>
                    Try it out
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <MiniPopUp popupOpen={popupOpen} closePopup={closePopup} />
    </div>
  );
};

export default MiniProjects;
