import React from "react";

const experienceData = [
  {
    title: "Full-stack web developer, freelance",
    description: [" Freelance missions"],
    link: {
      title: "ChoisyTech",
      url: "https://choisytech.com/",
    },
    duration: "Jun. 2024 - currently",
  },
  {
    title: "Full Stack Web Developer (Permanent Position)",
    description: [
      " JGEC. Creil, France",
      "Maintaining internal applications and providing external services.",
    ],
    link: {
      title: "Jgec.fr",
      url: "https://www.jgec.fr/",
    },
    duration: "Aug. 2022 - Apr. 2024",
  },
  {
    title: "Full Stack Web Developer Intern",
    description: [
      " JGEC. Creil, France",
      "Creation of the company's website",
      "creation of an LMS (learning management system)",
    ],
    link: {
      title: "Jgec.fr",
      url: "https://www.jgec.fr/",
    },
    duration: "Aug. 2020 - Apr. 2022",
  },

  {
    title: "Stage ouvrier",
    description: [
      "Assemblage des pièces de boite de vitesses (boite 8 vitesses).",
      "Punch Powerglide Strasbourg, France.",
    ],
    duration: "Aug 2018 - Dec 2018",
  },
];

const Experience = () => {
  return (
    <div className="work">
      <h3>
        <i className="fa fa-briefcase"></i>Experience
      </h3>
      <ul>
        {experienceData.map((experience, index) => (
          <li key={index}>
            <span>{experience.title}</span>
            {experience.description.map((desc, i) => (
              <small key={i}>
                {experience.link && i === 0 ? (
                  <>
                    <a
                      href={experience.link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      &rArr; {experience.link.title}
                    </a>
                    <span> - {desc}</span>
                  </>
                ) : (
                  <span>&rarr; {desc}</span>
                )}
              </small>
            ))}
            <small>{experience.duration}</small>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Experience;
