import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/portfolio/LandingPage";
import Project from "./pages/portfolio/Projects";
import MiniProject from "./pages/portfolio/MiniProjects";
import "./App.scss";
import Resume from "./pages/portfolio/Resume";

// import FirdawsHome from "./pages/firdaws/eng/FirdawsHome";
// import FirdawsDev from "./pages/firdaws/eng/FirdawsDev";
// import FrFirdawsHome from "./pages/firdaws/fr/FrFirdawsHome";
// import Test from "./pages/firdaws/fr/Test";
// import Blog from "./pages/firdaws/eng/Blog";
// import Single from "./pages/firdaws/eng/Single";
// import InstallSass from "./pages/blog/eng/InstallSass";
// import WebResources from "./pages/blog/eng/WebResources";
// import Academy from "./pages/firdaws/eng/Academy";
// import PersonalDev from "./pages/firdaws/eng/PersonalDev";
// import PersonalDevResources from "./pages/blog/eng/PersonalDevResources";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/miniprojects" element={<MiniProject />} />
        <Route path="/resume" element={<Resume />} />
        {/*<Route path="/firdaws" element={<FirdawsHome />} />
        <Route path="/firdaws-dev" element={<FirdawsDev />} />
        <Route path="/fr" element={<FrFirdawsHome />} />
        <Route path="/academy" element={<Academy />} />
        <Route path="/web-dev" element={<Blog />} />
        <Route path="/personal-dev" element={<PersonalDev />} />
        <Route path="/single" element={<Single />} />
        <Route path="/installing-sass" element={<InstallSass />} />
        <Route path="/web-resources" element={<WebResources />} />
        <Route
          path="/personal-dev-resources"
          element={<PersonalDevResources />}
        />
        <Route path="/test" element={<Test />} />*/}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
