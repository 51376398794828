// src/sanityClient.js

import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID, // find this at manage.sanity.io or in your sanity.json
  dataset: process.env.REACT_APP_SANITY_DATASET, // find this in sanity.json
  apiVersion: process.env.REACT_APP_SANITY_API_VERSION, // use a UTC date string
  useCdn: true, // `false` if you want to ensure fresh data
});
export const urlFor = (source) => imageUrlBuilder(client).image(source);
