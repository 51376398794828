import React from "react";
import PropTypes from "prop-types";
import { urlFor } from "../../sanityClient";

const PopUp1 = ({ content, closePopup }) => {
  const { _id, imageUrl, heading, subHeading, text, link, linkText } = content;

  return (
    <div className="popup" id={_id} onClick={closePopup}>
      <div className="popup__content">
        <div className="popup__left">
          <img src={urlFor(imageUrl)} alt="Project" className="popup__img" />
        </div>
        <div className="popup__right">
          <span className="popup__close">&times;</span>
          <h2 className="heading-secondary u-margin-bottom-small">{heading}</h2>
          <h3 className="heading-tertiary u-margin-bottom-small">
            {subHeading}
          </h3>
          <p className="popup__text">{text}</p>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn--green"
          >
            {linkText}
          </a>
        </div>
      </div>
    </div>
  );
};

PopUp1.propTypes = {
  content: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
  }).isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default PopUp1;
